import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_KEY } from "../utils/GlobalFunctions";
import axios from "axios";
import { message } from "antd";
import { fetchQuery } from "./fetchQueries";

const openUrl = axios.create({
  baseURL: "https://smartpay.tj/api/portal/",
  withCredentials: false,
  headers: {
    "x-app-token": API_KEY(),
  },
});

const initialState = {
  status: "",
  loading: false,
  departments: null,
  applicationData: null,
  formVisible: false,
  paymentVisible: false,
  tourOpen: false,
  breadCrumbNavigate: [{ title: "Hello", href: "/departments" }],
  dataServise: null,
  contentType: { type: "G", label: "Гражданам" },
  catalogDrawer: false,
  activeCategoryCode: null,
  checkedServices: [],
};

export const getDepartments = createAsyncThunk(
  "interfaceReducer/getDepartments",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      let uri = `vkd/departments`;

      const { data } = await openUrl.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

// export const getServise = createAsyncThunk(
//   "interfaceReducer/getServise",
//   async ({ unitId }, { rejectWithValue, dispatch }) => {
//     try {
//       let url = `vkd/services/${unitId}`;
//       const { data } = await openUrl.get(url);
//       return data;
//     } catch (error) {
//       message.error(error.message);
//       window.location.href = "https://smartpay.tj/404";
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const getApplication = createAsyncThunk(
  "interfaceReducer/getApplication",
  async (appId, { rejectWithValue, dispatch }) => {
    try {
      let url = `application/${appId}`;
      const { data } = await openUrl.get(url);
      return data;
    } catch (error) {
      message.error(error.message);
      // window.location.href = "https://smartpay.tj/404";
      return rejectWithValue(error.message);
    }
  }
);

export const getServise = createAsyncThunk(
  "interfaceReducer/getServise",
  async ({ code }, { rejectWithValue, dispatch }) => {
    try {
      let url = `${process.env.REACT_APP_API_URL}services/${code}`;
      const data = await fetchQuery(url);
      return data;
    } catch (error) {
      message.error(error.message);
      // window.location.href = "https://smartpay.tj/404";
      return rejectWithValue(error.message);
    }
  }
);

const interfaceSlice = createSlice({
  name: "interfaceState",
  initialState,
  reducers: {
    setBreadcrumbNavigate: (state, action) => {
      state.breadCrumbNavigate = action.payload;
    },
    setHomePageText: (state, action) => {
      state.homePageText = action.payload;
    },
    setFormVisible: (state, action) => {
      state.formVisible = action.payload;
    },
    setPaymentVisible: (state, action) => {
      state.paymentVisible = action.payload;
    },
    setTourOpen: (state, action) => {
      state.tourOpen = action.payload;
    },
    setContentType: (state, action) => {
      state.contentType = action.payload;
    },
    setCatalogDrawer: (state, action) => {
      state.catalogDrawer = action.payload;
    },
    setActiveCategoryCode: (state, action) => {
      state.activeCategoryCode = action.payload;
    },
    setCheckedServices: (state, action) => {
      state.checkedServices = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDepartments.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getDepartments.fulfilled, (state, action) => {
      state.status = "fulfilled";
      state.loading = false;
      state.departments = action.payload;
    });
    builder.addCase(getDepartments.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
    });
    builder.addCase(getServise.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getServise.fulfilled, (state, action) => {
      state.status = "fulfilled";
      state.loading = false;
      state.dataServise = action.payload;
    });
    builder.addCase(getServise.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(getApplication.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getApplication.fulfilled, (state, action) => {
      state.loading = false;
      state.applicationData = action.payload;
    });
    builder.addCase(getApplication.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  setBreadcrumbNavigate,
  setFormVisible,
  setPaymentVisible,
  setTourOpen,
  setContentType,
  setCatalogDrawer,
  setActiveCategoryCode,
  setCheckedServices,
} = interfaceSlice.actions;

export default interfaceSlice.reducer;
