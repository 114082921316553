import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_KEY, saveToken } from "../utils/GlobalFunctions";
import axios from "axios";
import { message } from "antd";

const openUrl = axios.create({
  baseURL: "https://smartpay.tj/api/v1/portal/",
  withCredentials: false,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
    "x-app-token": API_KEY(),
  },
});

const initialState = {
  loading: false,
  loginError: false,
  defaultPath: "/departments",
};

export const login = createAsyncThunk(
  "interfaceReducer/login",
  async ({ body, navigate }, { rejectWithValue, dispatch }) => {
    try {
      let url = `login`;
      const { data } = await openUrl.post(
        url,
        new URLSearchParams({
          username: body.username,
          password: body.password,
        })
      );
      navigate("/departments");
      saveToken(data.access_token);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    setDefaultPath: (state, action) => {
      state.defaultPath = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.status = "fulfilled";
      state.loading = false;
      state.loginError = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
      state.loginError = true;
    });
  },
});

export const { setDefaultPath } = authSlice.actions;

export default authSlice.reducer;
