import { combineReducers } from "@reduxjs/toolkit";
import interfaceReducer from "./InterfaceReducer";
import authReducer from "./Auth";

const rootReducer = combineReducers({
  interfaceState: interfaceReducer,
  authState: authReducer,
});

export default rootReducer;
