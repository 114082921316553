import { message } from "antd";
import { API_KEY } from "../utils/GlobalFunctions";

export const fetchQuery = async (url, body = { METHOD: "GET" }) => {
  try {
    const response = await fetch(url, {
      headers: {
        "x-app-token": API_KEY(),
      },
      ...body,
    });
    const data = response.json();
    return data;
  } catch (error) {
    message.error(error.message);
    return error;
  }
};
