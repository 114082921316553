import React, { Suspense } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import {
  Layout,
  Departments,
  Services,
  Application,
  ApplicationCreate,
  Login,
  Registration,
  ProfilePage,
  MyServices,
} from "./router/router";
import Spinner from "./components/ui/Spinner";

// export default class App extends React.Component {
//   constructor(props) {
//     super(props);
//   }
//   render () {

//     return (
//       <>
//           <Routes>
//             <Route path="/" element={<Layout />}>
//               <Route index element={<Departments />} />
//               <Route path='departments' element={ <Departments />}    />
//               <Route path='login' element={ <Login />}    />
//               <Route path='departments/:unitId/services' element={ <Services />}    />
//               <Route path='departments/:unitId/services/:appId' element={ <Application />}    />
//             </Route>
//           </Routes>
//       </>
//     );
//   }

// }
function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Spinner />}>
              <Layout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Spinner />}>
                <Departments />
              </Suspense>
            }
          />
          <Route
            path="departments"
            element={
              <Suspense fallback={<Spinner />}>
                <Departments />
              </Suspense>
            }
          />
          <Route
            path="login"
            element={
              <Suspense fallback={<Spinner />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="registration"
            element={
              <Suspense fallback={<Spinner />}>
                <Registration />
              </Suspense>
            }
          />
          <Route
            path="departments/:unitId/services"
            element={
              <Suspense fallback={<Spinner />}>
                <Services />
              </Suspense>
            }
          />
          <Route
            path="departments/services/:appId"
            element={
              <Suspense fallback={<Spinner />}>
                <Application />
              </Suspense>
            }
          />
          <Route
            path="departments/service_create/:appId"
            element={
              <Suspense fallback={<Spinner />}>
                <ApplicationCreate />
              </Suspense>
            }
          />
          <Route
            path="profile"
            element={
              <Suspense fallback={<Spinner />}>
                <ProfilePage />
              </Suspense>
            }
          />

          <Route
            path="myServices"
            element={
              <Suspense fallback={<Spinner />}>
                <MyServices />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
