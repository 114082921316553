import { lazy } from "react";

export const Layout = lazy(() => import("../components/Layout"));
export const Departments = lazy(() => import("../components/Departments"));
export const Services = lazy(() => import("../components/Services"));
export const Application = lazy(() => import("../components/Application"));
export const ApplicationCreate = lazy(() =>
  import("../components/ApplicationCreate")
);
export const Login = lazy(() => import("../components/Login"));
export const Registration = lazy(() => import("../components/Registration"));
export const ProfilePage = lazy(() => import("../components/ProfilePage"));
export const MyServices = lazy(() => import("../components/MyServices"));
